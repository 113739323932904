<template>
  <div class="bg-white py-3 flex items-center justify-between sm:px-2">
    <div class="sm:flex-1 sm:flex sm:items-center sm:justify-end">
      <div>
        <nav class="relative z-0 inline-flex rounded shadow-sm -space-x-px" aria-label="Pagination">
          <a
            @click.prevent="hasPrev() && changePage(prevPage)"
            href="#"
            :class="{ 'cursor-default': !hasPrev() }"
            class="relative inline-flex items-center rounded-l-sm border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50"
          >
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>

          <template v-if="hasFirst()">
            <a
              class="bg-gray-100 relative inline-flex items-center px-3 border border-gray-300 text-xs font-medium text-gray-700 hover:bg-gray-50"
              href="#"
              @click.prevent="changePage(1)"
            >
              1
            </a>
          </template>

          <span
            v-if="hasFirst()"
            class="relative inline-flex items-center px-3 border border-gray-300 bg-white text-xs font-medium text-gray-700"
          >
            ...
          </span>

          <template class="page-item" v-for="(page, index) in pages">
            <a
              href="#"
              @click.prevent="changePage(page)"
              :key="`page-${index}`"
              :class="
                current == page
                  ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center border text-sm font-medium'
                  : 'text-gray-700'
              "
              class="bg-gray-100 relative inline-flex items-center px-3 border border-gray-300 text-xs font-medium hover:bg-gray-50"
            >
              {{ page }}
            </a>
          </template>

          <span
            v-if="hasLast()"
            class="relative inline-flex items-center px-3 border border-gray-300 bg-white text-xs font-medium text-gray-700"
          >
            ...
          </span>

          <template v-if="hasLast()">
            <a
              class="bg-gray-100 relative inline-flex items-center px-3 border border-gray-300 text-xs font-medium text-gray-700 hover:bg-gray-50"
              href="#"
              @click.prevent="changePage(totalPages)"
            >
              {{ totalPages }}
            </a>
          </template>

          <a
            href="#"
            @click.prevent="hasNext() && changePage(nextPage)"
            :class="{ 'cursor-default': !hasNext() }"
            class="relative inline-flex items-center rounded-r-sm border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50"
          >
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'paginator',
  props: {
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    pageRange: {
      type: Number,
      default: 2,
    },
    textBeforeInput: {
      type: String,
      default: 'Go to page',
    },
    textAfterInput: {
      type: String,
      default: 'Go',
    },
  },
  data() {
    return {
      input: '',
    }
  },
  methods: {
    hasFirst: function () {
      return this.rangeStart !== 1
    },
    hasLast: function () {
      return this.rangeEnd < this.totalPages
    },
    hasPrev: function () {
      return this.current > 1
    },
    hasNext: function () {
      return this.current < this.totalPages
    },
    changePage: function (page) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('page-changed', page)
      }
    },
  },
  computed: {
    pages: function () {
      var pages = []
      for (var i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }
      return pages
    },
    rangeStart: function () {
      var start = this.current - this.pageRange
      return start > 0 ? start : 1
    },
    rangeEnd: function () {
      var end = this.current + this.pageRange
      return end < this.totalPages ? end : this.totalPages
    },
    totalPages: function () {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage: function () {
      return this.current + 1
    },
    prevPage: function () {
      return this.current - 1
    },
  },
}
</script>

<style></style>
