<template>
  <div>

    <div class="flex flex-col">
      <data-filters v-if="showFilters" v-model="searchFilters" />


      <div class="flex justify-between items-center mb-4">
        <h1 class="self-start self-center text-base font-medium mr-5">{{heading_title}}</h1>
        <div class="self-end flex justify-between items-center space-x-4">
          <div v-if="showStatus" class="">
            <select v-model="status" class="text-xs p-2 border-none shadow rounded-md">
              <option value="">Status auswählen</option>
              <option value="open">Offen</option>
              <option value="cancel">Abbrechen</option>
              <option value="done">Abgeschlossen</option>
            </select>
          </div>
          <div v-if="showCategory" class="">
            <select v-model="categorie" class="text-xs p-2 border-none shadow rounded-md">
              <option value="">Kategorie auswählen</option>
              <option
                v-for="cat in categories"
                :value="cat.categorie"
                :key="`categorie-${cat.id}`"
                >{{ cat.categorie }}</option
              >
            </select>
          </div>
          <date-picker
            input-class ="shadow p-2 border-none rounded-md bg-white"
            v-if="showCalendar"
            type="year"
            v-model="year"
            format="YYYY"
            value-type="format"
            :default-value="year"
            :clearable="false"
            :editable="false"
          />
        </div>
      </div>
      <div class="shadow p-2 pt-4 rounded-md bg-white">
      <data-repeater
        :title="title"
        :actions="computedActions"
        :columns="columns"
        :rows="rows"
        :show="show"
        @reload="init"
        :resource="resource"
        :permission="permission"
        :hide-category="hideCategory"
      />

      <paginator
        :current="currentPage"
        :total="totalPages * perPage"
        :per-page="perPage"
        @page-changed="pageChanged"
      />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import DataFilters from '@/components/DataTable/DataFilters'
import Paginator from '@/components/Commons/Paginator'

export default {
  name: 'data-table',
  props: {
    value: {
      required: true,
    },
    show: { default: true },
    heading_title: {
      type: String,
    },
    title: {
      type: String,
    },
    showFilters: {
      default: true,
    },
    showCalendar: {
      default: false,
    },
    showStatus: {
      default: false,
    },
    actions: {
      required: false,
    },
    permission: {
      require: true,
    },
    hideCategory: {
      default: false,
    },
    showCategory: {
      default: false,
    },
  },
  components: { DataFilters, Paginator },
  data() {
    return {
      columns: this.value.columns,
      rows: this.value.rows,
      saved: false,
      currentPage: 1,
      totalPages: 0,
      total: 0,
      perPage: 25,
      searchFilters: this.value.searchFilters,
      year: null,
      status: '',
      resource: null,
      categorie: '',
      categories: [],
    }
  },
  mounted() {
    this.year = this.moment().format('YYYY')
    this.resource = this.value.resource
    this.resource && this.init()

    this.$bus.$off('reload-rating-data-table')
    this.$bus.$off('reload-template-data-table')
    this.$bus.$off('reload-groups-data-table')
    this.$bus.$off('reload-pdf-settings-data-table')
    this.$bus.$off('reload-notification-settings-data-table')
    this.$bus.$off('reload-development-actions', this.init)

    this.$bus.$on('reload-rating-data-table', this.init)
    this.$bus.$on('reload-template-data-table', this.init)
    this.$bus.$on('reload-groups-data-table', this.init)
    this.$bus.$on('reload-pdf-settings-data-table', this.init)
    this.$bus.$on('reload-notification-settings-data-table', this.init)
    this.$bus.$on('reload-development-actions', this.init)

    if (this.showCategory) {

      this.axios.get(`/organisation/feedback/categorie`).then(response => {
        this.categories = response.data.results
      })
    }
  },
  methods: {
    init() {
      let query = ''

      _.each(
        _.omitBy(
          _.omitBy(_.omitBy(this.searchFilters, _.isNull), _.isEmpty),
          _.isEmpty
        ),
        (value, key) => {
          query = `${query}&${key}=${value}`
        }
      )

      if (query) {
        query = `${query}&archived=${this.searchFilters.archived}`
        if (this.showCalendar) query = `${query}&search=${this.year}`
        if (this.showStatus)
          query = `${query}${this.status ? `,${this.status}` : ``}`
        if (this.showCategory)
          query = `${query}${this.categorie ? `,${this.categorie}` : ``}`
      } else if (this.query) query = `search=${this.query}`

      this.axios
        .get(`/${this.resource}/?${query ? query : `page=${this.currentPage}`}`)
        .then(response => {
          if (response.status === 200) {
            this.rows = response.data.results

            this.perPage =
              this.currentPage === 1 ? this.rows.length : this.perPage
            this.totalPages = response.data.count / this.perPage

            this.$emit('loaded', true)
          } else {
            console.log('Response Error ::: ', response.statusText)
          }
        })
    },
    pageChanged(page) {
      this.currentPage = page
      this.init()
    },
  },
  computed: {
    computedActions() {
      return this.actions || this.value.actions
    },
  },
  watch: {
    create: function(n) {
      !n && this.init()
    },
    searchFilters: {
      handler: function() {
        this.init()
        this.currentPage = 1
      },
      deep: true,
    },
    query: function() {
      this.init()
    },
    year: function() {
      this.init()
    },
    status: function() {
      this.init()
    },
    categorie: function() {
      this.init()
    },
  },
}
</script>

<style></style>
