<template>
  <div class="flex flex-col relative mb-2 rounded">
    <div
      class="flex flex-col md:flex-row text-xs justify-between text-blue-900"
    >
      <div
        v-if="localFilters.length"
        class="flex overflow-x-scroll hide-scroll-bar md:mr-8"
      >
        <a
          @click.prevent="updateFilter(temp)"
          href="#"
          class="flex flex-nowrap"
        >
          <div class="inline-block px-1">
            <div
              class="flex items-center px-2 py-0.5 space-x-2 rounded text-xs bg-indigo-100 text-indigo-800"
            >
              <span>Alle</span>
            </div>
          </div>
        </a>

        <template v-for="(filters, f_index) in localFilters">
          <div
            v-for="(value, filter, findex) in filters"
            :key="`filter-${f_index}-${findex}`"
            class="flex flex-nowrap"
            href="#"
          >
            <div class="inline-block px-1">
              <div
                class="flex items-center px-2 py-0.5 space-x-2 rounded text-xs bg-indigo-100 text-indigo-800"
              >
                <a href="#" class="flex" @click.prevent="updateFilter(value)">
                  <span>{{ filter }}</span>
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 cursor-pointer"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  @click="removeFilter(f_index)"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </template>
      </div>
      <span v-else></span>

      <div class="flex justify-end">
        <div class="mr-8 flex items-center">
          <div class="z-40 inline-block text-left dropdown">
            <div class="flex space-x-1">
              <div class="w-4 h-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <a href="#" @click="show = !show"> Filter </a>
            </div>
          </div>
        </div>

        <div class="relative rounded border">
          <button
            @click.prevent="updateQuery(null)"
            class="absolute inset-y-0 right-0 pr-1 flex items-center z-20 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              v-if="!query"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              v-else
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <input
            type="text"
            v-model="q"
            class="block w-full pr-8 shadow p-5 border-none rounded-md bg-white px-4 py-2 focus:outline-none z-10 relative"
            placeholder="Suchen ..."
            @keypress.enter="updateQuery(q)"
          />
        </div>
      </div>
    </div>

    <div
      v-show="show"
      class="border-none rounded-md bg-white mt-2 shadow border overflow-hidden"
    >
      <div class="flex flex-col">
        <div
          class="px-3 py-2 bg-gray-200 border-rounded-t flex justify-between"
        >
          <h3 class="text-gray-700 font-semibold text-sm">Filter festlegen</h3>
          <a href="#" @click.prevent="show = false"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              /></svg
          ></a>
        </div>
        <div class="p-4 bg-white">
          <div class="mb-4">
            <div class="space-y-4">
              <div class="w-full flex justify-between">
                <div class="w-1/4 flex space-x-2">
                  <input
                    type="checkbox"
                    class="border rounded px-2 py-1 focus:outline-none"
                    v-model="localFilter.archived"
                  />
                  <label for="contact_number">Ausgetretene Mitarbeiter</label>
                </div>
              </div>

              <div class="w-full flex justify-between">
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">Personalnummer</label>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none"
                    v-model="localFilter.personal_number"
                  />
                </div>
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">Vorname</label>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none"
                    v-model="localFilter.first_name"
                  />
                </div>
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">Nachname</label>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none"
                    v-model="localFilter.last_name"
                  />
                </div>
              </div>

              <div class="w-full flex justify-between">
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">Postleitzahl</label>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none"
                    v-model="localFilter.postcode"
                  />
                </div>
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">Ort</label>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none"
                    v-model="localFilter.place"
                  />
                </div>
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">AHV Nummer</label>
                  <input
                    type="text"
                    class="w-full border rounded px-2 py-1 focus:outline-none"
                    v-model="localFilter.ahv_number"
                  />
                </div>
              </div>

              <div class="w-full flex justify-between">
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">Geburtsdatum</label>
                  <date-picker
                    :input-class="
                      `w-full border rounded px-2 py-1 focus:outline-none`
                    "
                    v-model="localFilter.birth_date"
                    range
                  />
                </div>
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">Eintrittsdatum</label>
                  <date-picker
                    :input-class="
                      `w-full border rounded px-2 py-1 focus:outline-none`
                    "
                    v-model="localFilter.start_date"
                    range
                  />
                </div>
                <div class="w-1/4 flex flex-col">
                  <label for="contact_number">Austrittsdatum</label>
                  <date-picker
                    :input-class="
                      `w-full border rounded px-2 py-1 focus:outline-none`
                    "
                    v-model="localFilter.end_date"
                    range
                  />
                </div>
              </div>
            </div>
          </div>

          <hr class="border-t border-gray-200 my-8" />

          <div class="flex space-x-2">
            <a
              href="#"
              @click.prevent="updateAndApply"
              class="bg-blue-600 px-3 py-1.5 text-white rounded"
              >Filter anwenden</a
            >
            <a
              v-show="filterCountLimit"
              href="#"
              @click.prevent="saveFilters"
              class="text-blue-600 px-3 py-1.5 border border-blue-600 rounded"
              >Filter speichern</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateMe } from '@/services/auth'

const tempFilter = {
  archived: false,
  personal_number: null,
  first_name: null,
  last_name: null,
  postcode: null,
  place: null,
  ahv_number: null,
  birth_date: null,
  start_date: null,
  end_date: null,
}

export default {
  name: 'data-filters',
  props: ['value'],
  components: {},
  data() {
    return {
      q: '',
      show: false,
      localFilters: [],
      localFilter: { ...tempFilter },
      temp: { ...tempFilter },
    }
  },
  mounted() {
    this.init()
    this.$bus.$off('update-me')
    this.$bus.$on('update-me', this.saveFilter)

    this.localFilters = this.profile.custom_filters.length
      ? !Array.isArray(this.profile.custom_filters)
        ? [...JSON.parse(this.profile.custom_filters)]
        : [...this.profile.custom_filters]
      : []
  },
  methods: {
    init() {},
    // searchHandler() {
    //   if (this.query) this.updateQuery(null)
    //   else this.q && this.updateQuery(this.q)
    // },
    saveFilter(payload) {
      if (payload) {
        this.localFilters.push({
          [payload.name]: { ...this.localFilter },
        })

        this.localFilter = { ...tempFilter }

        this.updateMeFilters()
      }
    },
    updateAndApply() {
      const localFilter = { ...this.localFilter }

      const birth_date = Array.isArray(localFilter.birth_date)
        ? {
            birth_date_after: localFilter.birth_date[0],
            birth_date_before: localFilter.birth_date[1],
          }
        : {}

      const start_date = Array.isArray(localFilter.start_date)
        ? {
            start_date_after: localFilter.start_date[0],
            start_date_before: localFilter.start_date[1],
          }
        : {}

      const end_date = Array.isArray(localFilter.end_date)
        ? {
            leaving_date_after: localFilter.end_date[0],
            leaving_date_before: localFilter.end_date[1],
          }
        : {}

      delete localFilter['birth_date']
      delete localFilter['start_date']
      delete localFilter['end_date']

      this.$emit('input', {
        ...localFilter,
        ...birth_date,
        ...start_date,
        ...end_date,
      })
    },
    updateMeFilters() {
      const { first_name, last_name, email } = this.profile

      updateMe(
        {
          first_name,
          last_name,
          email,
          custom_filters: this.localFilters.length
            ? JSON.stringify(this.localFilters)
            : '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then(response => {
        const updatedData = {
          ...response.data,
          custom_filters:
            response.data.custom_filters.length &&
            JSON.parse(response.data.custom_filters),
        }

        this.localFilter = {}
        this.$emit('input', { ...this.localFilter })

        this.updateProfile({
          ...this.profile,
          ...updatedData,
        })
      })
    },
    removeFilter(index) {
      this.localFilters.splice(index, 1)
      this.updateMeFilters()
    },
    saveFilters() {
      this.$bus.$emit('show-modal', {
        show: true,
        title: 'Filter speichern',
        component: 'save-filter',
        maxWidth: 'w-lg',
        event: 'update-me',
      })
    },
    updateFilter(filter) {
      this.localFilter = {
        ...filter,
      }
      this.updateAndApply()
    },
  },
  computed: {
    filterCountLimit() {
      if (this.localFilters && this.localFilters.length) {
        return this.localFilters.length < 5
      }
      return true
    },
  },
  watch: {
    query: function(n) {
      this.q = n
    },
  },
}
</script>

<style scoped>
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
</style>
